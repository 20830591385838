import UserSettings, {
  deleteTempUserSettings,
  getTempUserSettings,
} from "@vgno/user-settings";
import { getIdentityStore } from "@vgno/account";

export const syncDarkmode = async () => {
  const identity = await getIdentityStore();
  if (!(await identity?.isLoggedIn())) {
    return;
  }

  const userData = await identity?.getUser();
  if (!userData) {
    return;
  }
  const userSettings = new UserSettings(userData);
  const tempSettings = getTempUserSettings();

  if (tempSettings.darkmode !== undefined) {
    userSettings.set("darkmode", tempSettings.darkmode);
    deleteTempUserSettings();
  }
};
